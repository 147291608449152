const actions = {
    errorAlert(error, $refs) {
        if ($refs.alerts) {
            $refs.alerts.setAlert('error', error)
            $refs.alerts.showAlert()
        }
    }
}

export default {
    actions
}
