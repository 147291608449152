

import { createStore } from 'vuex'

import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import banner from './modules/banner';
import campaign from './modules/campaign';
import categories from './modules/categories';
import user from './modules/user';
import withdrawal from './modules/withdrawal';
import notification from './modules/notification';
import google from './modules/google';
import errorHandling from "./modules/errorHandling";
import home from './modules/home';
import dashboard from './modules/dashboard';
import intro from './modules/intro';
import facebookpixel from './modules/facebookpixel';

export default createStore({
  modules: {
    auth,
    banner,
    campaign,
    categories,
    user,
    withdrawal,
    notification,
    google,
    errorHandling,
    home,
    dashboard,
    intro,
    facebookpixel
  },
  plugins: [createPersistedState()]
});
