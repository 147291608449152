import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'splash',
    component: () => import('../views/SplashScreen.vue')
  },
  {
    path: '/onboard',
    name: 'onboardOne',
    component: () => import('../views/OnboardingPage.vue')
  },
  {
    path: '/bookmark',
    name: 'bookmark',
    component: () => import('../views/BookmarkPage.vue')
  },
  {
    path: '/campaign',
    name: 'campaign',
    component: () => import('../views/CampaignPage.vue')
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/DashboardPage.vue')
  },

  {
    path: '/campaign/:id',
    name: 'campaign-with-category',
    component: () => import('../views/CampaignPage.vue')
  },

  {
    path: '/campaign/detail/:id',
    name: 'campaign-detail-category',
    component: () => import('../views/CampaignDetailPage.vue')
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginPage.vue')
  },

  {
    path: '/login/:token',
    name: 'login-token',
    component: () => import('../views/LoginPage.vue')
  },

  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterPage.vue')
  },
  {
    path: '/register/:referral_code',
    name: 'register-by-referral',
    component: () => import('../views/RegisterPage.vue')
  },

  {
    path: '/reset',
    name: 'reset',
    component: () => import('../views/ResetPasswordPage.vue')
  },

  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomePage.vue')
  },

  {
    path: '/basket',
    name: 'basket',
    component: () => import('../views/BasketPage.vue')
  },

  {
    path: '/notification',
    name: 'notification',
    component: () => import('../views/NotificationPage.vue')
  },

  {
    path: '/account',
    name: 'accout',
    component: () => import('../views/AccountPage.vue')
  },

  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: () => import('../views/WithdrawalPage.vue')
  },

  {
    path: '/withdrawal/create',
    name: 'withdrawal-create',
    component: () => import('../views/WithdrawalCreate.vue')
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
