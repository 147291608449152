//store/modules/auth.js

import axios from 'axios';
import MainApp from '@/router'

const cors = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*' // Could work and fix the previous problem, but not in all APIs
      }
}

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    
    async getNotification({dispatch}, form) {
        const headers =  {
            Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
        }


        const response = await axios.get('notification-list',{ params: form, headers: headers})
        return response
    },

    async getNotificationfcm({dispatch}, form) {
        const headers =  {
            Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
        }


        const response = await axios.get('notification-fcm',{ params: form, headers: headers})
        return response
    },

    async readNotification({dispatch}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
            }
        }

        const response = await axios.post('notification-read',form, headers)
        return response
        
    },
    
    async subscribePushNotification({dispatch}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: $cookies.get("ZEALS_TOKEN") ? `Bearer ${$cookies.get("ZEALS_TOKEN")}` : '',
            }
        }

        const response = await axios.post('notification-subscribe',{token:form}, headers)
        return response
        
    },

    
};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
